<template>
  <div class="bg">
    <div class="container" style="min-height: 83vh;">
      <div class="bg">
        <div class="container" style="padding-bottom: 50px;">
          <img
              src="@/assets/start.svg"
              style="margin-bottom: 50px; padding-top: 30px;"
              alt=""
          />
          <h1 style="font-weight: bold;">Let's start now</h1>
          <p>{{ $t("helpSent") }}</p>
        </div>
      </div>


      <form @submit.prevent="startCourse">

        <div class="row">
          <div class="col-xl-6 col-md-6 col-sm-12">
            <div class="row" style="padding-bottom: 50px;">
              <p class="col-xl-6 col-md-6 col-sm-12">I speak</p>
              <b-form-select
                  class="col-xl col-md col-sm-12 ml-3 mr-3"
                  text-field="name"
                  value-field="id"
                  v-model="fromLanguageId"
                  :options="fromLanguages"
                  required
              ></b-form-select>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12">
            <div class="row" style="padding-bottom: 50px;">
              <p class="col-xl-6 col-md-6 col-sm-12">I want to learn</p>
              <b-form-select
                  class="col-xl col-md col-sm-12 ml-3 mr-3"
                  text-field="name"
                  value-field="id"
                  v-model="toLanguageId"
                  :options="toLanguages"
                  required
              ></b-form-select>
            </div>
          </div>
        </div>
        <b-button
            class="mainButton"
            type="submit"
            style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-bottom: 20px; margin-top: 50px;"
        >
          {{ $t("continue") }}
        </b-button>
      </form>
    </div>

    <b-modal
        size="md"
        ref="error-modal"
        centered
        id="select-modal"
        hide-footer
        hide-header
        body-class="loginModal"
    >
      <div
          style="text-align: right; margin-bottom: -50px; margin-right: -30px;"
      >
        <img
            src="@/assets/closeButt.svg"
            @click="hideErrorModal"
            style="cursor: pointer;"
            alt=""
        />
      </div>
      <div class="d-block text-center">
        <h4
            style="text-align: center; padding-bottom: 30px;  font-weight: bold; color: #1E2E4D;"
        >
          Oops!
        </h4>
        <p style="text-align: center; margin-bottom: 30px; color: #5F6B81;">
          {{ response.toLowerCase() }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Selection",
  data() {
    return {
      name: "",
      fromLanguageId: 0,
      response: "",
      toLanguageId: 0,
    };
  },


  computed: {
    ...mapState({
      apiBase: (state) => state.global.apiBase,
      token: (state) => state.auth.token,
      languages: (state) => state.global.languages,
    }),
    fromLanguages() {
      return this.languages.filter((el) => el.id !== this.toLanguageId);
    },
    toLanguages() {
      return this.languages.filter((el) => el.id !== this.fromLanguageId);
    },
  },

  async mounted() {
  },

  methods: {
    showErrorModal(error) {
      this.response = error;
      this.$bvModal.show('error-modal');
    },
    hideErrorModal() {
      this.$bvModal.hide("error-modal");
    },
    startCourse() {

      fetch(
          this.apiBase +
          "/api/v1/learning/start?fromLanguageId=" +
          this.fromLanguageId +
          "&toLanguageId=" +
          this.toLanguageId +
          "&token=" +
          this.token,
          {
            method: "POST",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              this.$router.push({
                name: "CategoryList",
                params: {id: result.id},
              });
            } else {
              this.showErrorModal(result.message);
            }
          })
          .catch((error) => console.log("error", error));
    },

  },

  components: {},
  props: {},

};
</script>

<style scoped>
.bg {
  background: #f7f8fc;
}

.lang:hover {
  background: white;
  border-radius: 10px;
}

.selected {
  background: white;
  border-radius: 10px;
  box-shadow: 3px 5px 3px 3px rgba(197, 195, 195, 0.3);
}
</style>
