<template>
  <div>
    <b-jumbotron class="jtron d-flex align-items-center" fluid style="min-height: 90vh;">
      <template #header>
        <div
            class="col-xl-12 col-md-12 col-sm-12 mb-5"
            style=" font-weight: bold; font-size: 44px; text-align: center; color: white;"
        >
          {{ $t("ShowcaseHeader") }}
        </div>
      </template>

      <template #lead>
        <div
            class="col-xl-12 col-md-12 col-sm-12"
            style="font-size: 18px; text-align: center; color: white;"
        >
          <p style="margin-bottom: 20px;" class="mb-5">
            {{ $t("ShowcasePar") }}
          </p>

          <div class="row text-left justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="" >
                <label>
                  {{ $t("speak") }}
                </label>
                <b-form-select
                    v-model="fromLanguageId"
                    text-field="name"
                    value-field="id"
                    :options="fromLanguages"
                ></b-form-select>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="" >
                <label>
                  {{ $t("learn") }}
                </label>
                <b-form-select
                    v-model="toLanguageId"
                    text-field="name"
                    value-field="id"
                    :options="toLanguages"
                ></b-form-select>
              </div>
            </div>
            <div class="col-lg-3 text-xl-right text-center ">
              <label>&nbsp;</label> <br>
              <b-button
                  class="mainButton "
                  id="arrow"
                  @click.prevent="$emit('login')"
                  style="font-weight: 600; border-radius: 5px; font-size: 15px; padding: 8px 15px 8px 15px;"
              >
                {{ $t("learn") }}
                <img src="@/assets/arrow.svg" id="arrow1" alt=""
                />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-jumbotron>
    <div class="container" style="padding-bottom: 20px;">
      <div class="row" style="text-align: left;">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <img src="@/assets/about.svg" alt=""/>
          <h4 style="font-weight: 600; font-size: 21px;">{{ $t("Wabout") }}</h4>
          <p>{{ $t("whatText") }}</p>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <img src="@/assets/for.svg" alt=""/>
          <h4 style="font-weight: 600; font-size: 21px;">{{ $t("Wfor") }}</h4>
          <p>{{ $t("whoText") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Jtron",
  data() {
    return {
      fromLanguageId: 0,
      toLanguageId: 0,
    };
  },
  mounted() {
  },
  methods: {},

  components: {},
  props: {},

  computed: {
    ...mapState({
      languages: (state) => state.global.languages,
    }),
    fromLanguages() {
      return this.languages.filter((el) => el.id !== this.toLanguageId);
    },
    toLanguages() {
      return this.languages.filter((el) => el.id !== this.fromLanguageId);
    },
  },
};
</script>

<style scoped>
.jtron {
  background-image: linear-gradient(
      0deg,
      rgba(28, 80, 175, 0.8),
      rgba(28, 80, 175, 0.8)
  ),
  url("assets/jback.svg");
  background-size: cover;
}
</style>
