<template>
  <div class="bg" v-if="progressData">
    <div style="background: white; padding-top: 20px; text-align: left;">
      <div class="container">
        <div class="row">
          <div
              class="col-lg-8 col-sm-12"
              style="margin-top: 10px; margin-bottom: 10px;"
          >
            <div class="row" v-if="currentCourse">
              <div class="col-lg">
                <small style="color: grey; font-weight: bold;">{{ $t("cCategory") }}</small>
                <h5
                    v-b-modal.courses
                    style="color: #1c50af; font-weight: bold;"
                >
                  {{ currentCourse.category.name }}
                </h5>
              </div>
              <div class="col-lg">
                <small style="color: grey; font-weight: bold;"> {{ $t("CTopic") }}</small>
                <h5
                    @click="getCourseList(currentCourse.category)"
                    style="color: #1c50af; font-weight: bold; cursor: pointer;"
                >
                  {{ currentCourse.name }}
                </h5>
              </div>
            </div>
            <div v-else>
              <small style="color: grey; font-weight: bold;">{{
                  $t("CTopic")
                }}</small>
              <h5 v-if="catId !== 0" style="color: #1c50af; font-weight: bold;">
                Category Exam
              </h5>
              <h5 v-else style="color: #1c50af; font-weight: bold;">
                General Exam
              </h5>
            </div>
          </div>
          <div
              class="col-lg text-left  text-lg-right"
              style="text-align: center; margin-top: 10px; margin-bottom: 10px;"
          >
            <b-button
                v-if="currentCourse"
                :to="{
                name: 'Practice',
                params: { id: progressId, courseId: currentCourse.id, catId: currentCourse.category.id },
              }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #48BF92; border: none;"
            >
              <img src="@/assets/testicons/pr.svg" alt=""/>
              {{ $t("gPractice") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
        size="lg"
        ref="courses"
        centered
        id="courses"
        hide-footer
        hide-header
        body-class="loginModal"
    >
      <div style="text-align: right; margin-bottom: -30px; margin-right: -30px;">
        <img src="@/assets/closeButt.svg" @click="hideCategoryModal" style="cursor: pointer;" alt=""/>
      </div>
      <div>
        <div class="d-flex"
             v-for="(cat, i) in catList"
             :key="i"
             style="border-left: 3px solid #E8EAEF;"
             v-bind:class="{ complete: cat.listOrder < currentProgressCategoryOrder }"
        >

          <div style="width: 40px;">
            <img
                v-if="cat.listOrder < currentProgressCategoryOrder"
                src="@/assets/check1.svg"
                style="margin-left: -10px; margin-top: -9px;"
                alt=""
            />
            <div
                v-else
                style="height: 28px; width: 28px; background: #E8EAEF; border-radius: 50%; margin-left: 4px; text-align: center;"
            >
              {{ i + 1 }}
            </div>
          </div>
          <div class="w-100 p-3 ml-3 shadow mb-4"
               style=" border-radius: 10px; cursor: pointer;"
               @click="getCourseList(cat)"
          >
            <h4 class="row mb-0 pb-0" :class="{'text-secondary':cat.listOrder > currentProgressCategoryOrder }">
              <span class="col font-weight-bold">{{ cat.name }} </span
              ><span v-if="cat.id === currentProgressCategoryOrder" class="col-3"
            ><img src="@/assets/categoryIcons/progress.svg" alt=""
            /></span>
            </h4>
            <p class="pb-0 pt-1" v-if="cat.description.trim()">
              {{ cat.description }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
        size="lg"
        ref="courses2"
        centered
        id="courses2"
        hide-footer
        hide-header
        body-class="loginModal"
    >
      <div
          style="text-align: right; margin-bottom: -30px; margin-right: -30px;"
      >
        <img
            src="@/assets/closeButt.svg"
            @click="hideCourseModal"
            style="cursor: pointer;"
            alt=""
        />
      </div>

      <div class="w-100 p-3 ml-3 shadow mb-4"
           style=" border-radius: 10px;" v-if="activeCategory"
      >
        <h4 class="row mb-0 pb-0" :class="{'text-secondary':activeCategory.listOrder > currentProgressCategoryOrder }">
              <span class="col font-weight-bold">{{ activeCategory.name }} </span
              >
        </h4>
        <p class="pb-0 pt-1" v-if="activeCategory.description.trim()">
          {{ activeCategory.description }}
        </p>
      </div>


      <div class="list-group ml-4">
        <div class="list-group-item d-flex justify-content-between align-items-center"
             v-for="(course, i) in courseList"
             :key="i"
        >
          <span class="font-weight-bold">{{ course.name }}</span>
          <span class="badge badge-primary badge-pill"
                v-if="course.id === currentCourse.id">
            Current
          </span>
          <div v-if="course.category.listOrder< currentProgressCategoryOrder
          || (course.category.listOrder === currentProgressCategoryOrder && progressData.course.listOrder >= course.listOrder ) ">
            <b-button
                class="secButton"
                :to="{
              name: 'CourseMaterial',
              params: { progressId: progressId, courseId: course.id, catId: course.category.id },
            }"
            >
              {{ $t("continue") }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <router-view :key="$route.path" style="margin-top: 35px;"
                 @course="currentCourse = $event "/>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "PracticePage",
  data() {
    return {
      progressId: "",
      courseId: 0,
      catId: 0,
      catList: [],
      courseList: [],
      progressData: [],
      currentProgressCategoryOrder: 0,
      activeCategory: null,
      currentCourse: null
    };
  },
  async mounted() {
    this.progressId = this.$route.params.progressId;
    this.courseId = this.$route.params.courseId;
    this.catId = this.$route.params.catId;

    this.getCategoryList();
    this.getProgress(this.progressId);
  },

  methods: {
    // ...mapActions(["removeFavorite", "addToFavorites"]),
    // change() {
    //   this.$router.push({
    //     name: "CourseMaterial",
    //     params: { progressId: this.id, courseId: 22 },
    //   });
    // },
    hideCategoryModal() {
      this.$refs["courses"].hide();
    },
    hideCourseModal() {
      this.$refs["courses2"].hide();
    },
    getProgress(progressId) {
      fetch(
          this.apiBase + "/api/v1/learning/" + progressId + "?token=" + this.token,
          {
            method: "GET",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              this.progressData = result.userProgress;
              this.currentCourse = result.userProgress.course;
              this.currentProgressCategoryOrder = result.userProgress.category.listOrder;
            } else {
              //todo alert and redirect to progress list page
            }
          })
          .catch((error) => console.log("error", error));
    },
    getCategoryList() {

      fetch(this.apiBase + "/api/v1/categories", {
        method: "GET",
        redirect: "follow",
      })
          .then((response) => response.json())
          .then((result) => {
            this.catList = result.categories;
          })
          .catch((error) => console.log("error", error));
    },

    getCourseList(category) {

      fetch(
          this.apiBase + "/api/v1/category/" + category.id + "/courses",
          {
            method: "GET",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            this.courseList = result.courseList;
            this.$bvModal.show("courses2");
            this.activeCategory = category;

          })
          .catch((error) => console.log("error", error));
    },
    routGo(val) {

      fetch(
          this.apiBase + "/api/v1/category/" + val + "/courses",
          {
            method: "GET",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            this.$router.push({
              name: "CourseMaterial",
              params: {progressId: this.progressId, courseId: result.courseList[0].id},
            });
          })
          .catch((error) => console.log("error", error));
    },
  },

  components: {},
  props: {},

  computed: {

    ...mapState({
      apiBase: (state) => state.global.apiBase,
      token: state => state.auth.token,
    }),

  },
};
</script>

<style scoped>
.navItem {
  color: #f7f8fc;
  font-size: 16px;
  font-weight: 500;
  padding: 9px;
  border-radius: 8px;
}

.navItemSelected {
  background: #48bf92;
}

.ddItem {
  text-align: right;
}

.secButton {
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  background: #eee1c4;
  border-color: #eee1c4;
  color: #f4a900;
}

.secButton:hover {
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  background: #f4a900;
  border-color: #f4a900;
  color: white;
}

.complete {
  border-left: 3px solid #48bf92 !important;
}
</style>

<style>
.loginModal {
  background: #f7f8fc;
  padding-right: 40px;
  padding-left: 40px;
  border-radius: 10px;
}

.modal-md {
  max-width: 400px !important;
}
</style>
