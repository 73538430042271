<template>
<div style="height: 50vh">
</div> 
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'Success',
  data(){
      return{
        }
  },
  async mounted(){

    let trx = this.$route.query.trans_id;

    if(trx) {

      fetch(
          this.ssoBase + "/login/end?transactionId=" + trx,
          {method: "GET", redirect: "follow",}
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              this.$store.dispatch("auth/setToken", result.token);
              localStorage.setItem("token", result.token);
              this.loginSuccess();
              this.$router.push({name: 'Landing'});
            } else {
              this.loginFailed();
              this.$router.push({name: 'Landing'});
            }
          }).catch((error) => console.log("error", error));

    } else {
      this.$router.push({name: 'Landing'});
    }

  },

  methods : {
     
  },

  notifications:{
    loginSuccess:{
      title: 'Login Success',
      message: 'You have successfully logged in',
      type: 'success'
    },
    loginFailed:{
      title: 'Login Failed',
      message: 'You have failed to login',
      type: 'error'
    },
  },

  components: {

  },
  props: {

  },

  computed: {
    ...mapState({
      token: state => state.auth.token,
      apiBase: state => state.global.apiBase,
      ssoBase: state => state.global.ssoBase,
    })
  },
}

</script>

<style scoped>
.bg{
    background: #F7F8FC;
}

</style>
