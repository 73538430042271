<template>
  <img :src="`/flag/${code}.png`" style="width: 25px;" alt=""/>
</template>
<script>
export default {
  name: 'LanguageIcon',
  props: {
    code: {
      type: String,
      default: 'en'
    }
  },
  computed: {
  },
}
</script>
