<template>
  <div style="">
    <Jtron @login="$emit('login')" />
    <Wiki @login="$emit('login')" />
    <CategoryScroll  @login="$emit('login')"/>
    <Why/>
    <!-- <Videos /> -->
  </div>
</template>

<script>
import {mapState} from 'vuex';

import Jtron from "./Jtron.vue"
import Wiki from "./Wiki.vue"
import Why from "./Why.vue"
// import Videos from "./Videos.vue"
import CategoryScroll from "./CategoryScroll.vue"

export default {
  name: 'Landing',
  data() {
    return {}
  },

  computed: {
    ...mapState({
      token: (state) => state.auth.token,
    }),
  },

  watch: {
    token: function (newToken) {
      if (newToken) {
        this.$router.push({name: 'Category', params: {}});
      }
    },
  },

  components: {
    Jtron,
    Wiki,
    Why,
    CategoryScroll,
  },
  props: {},

  mounted() {
    if (this.token) {
      this.$router.push({name: 'Category', params: {}});
    }
  }

}

</script>

<style scoped>


</style>
