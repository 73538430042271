<template>
  <div class="pb-2" style="text-align: left; min-height: 81vh;">
    <div v-if="account" class="container" style="padding-top: 50px; padding-bottom: 50px;">
      <h3 style="font-weight: bold;">
        {{ $t("hello") }}, {{ account.username }}
      </h3>
      <div class="row" style="margin-top: 30px; margin-bottom: 30px;">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="row">
            <div class="col-xl-3 col-md-3 col-sm-12">
              <img
                  v-if="account.avatar"
                  :src="avatarBase + account.avatar"
                  alt="avatar"
                  style="margin-bottom: 20px; width: 120px;"
              />
              <div v-else>
                <img src="@/assets/profile.svg" style="width:150px;"/>
              </div>
            </div>
            <div class="col-xl-9 col-md-9 col-sm-12">
              <p>
                <img src="@/assets/mobile.svg" style="" alt=""/>
                {{ $t("mobNumb") }}: +{{ account.msisdn }}
              </p>
              <router-link
                  class=""
                  style="text-decoration: none;"
                  id=""
                  :to="{ name: 'Profile', params: {} }"
              >
                <p style="text-decoration: underline;">
                  <img src="@/assets/edit.svg" style="" alt=""/>
                  {{ $t("edit") }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container" style="padding-top: 50px; padding-bottom: 50px;"  >
      <h3 style="font-weight: bold;">Welcome to Zunilingo!</h3>
      <p>
        Register or login if you have an existing accout to proceed to courses
      </p>
    </div>

    <div class="container-fluid bg" style="padding-bottom: 50px;">
      <div class="container" style="padding-top: 20px;">
        <h3 style="font-weight: bold; padding-top: 20px; padding-bottom: 20px;">
          {{ $t("mCourse") }}
        </h3>
        <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p>
        <div
            class="col"
            style="padding: 15px; margin-bottom: 12px; background: #fdfeff; border-radius: 7px;"
            v-for="cat in progList"
            :key="cat.id"
        >
          <div class="row">
            <div
                class="col-xl-2 col-md-2 col-sm-12"
                style="text-align: center;"
            >
              <!-- <img src="@/assets/flags/en.svg"  style="width: 50px; margin-bottom: 10px;" alt=""> -->
              <div class="" style="margin-bottom: 10px;">
                <LanguageIcon :code="cat.fromLanguage.code" style="width: 50px; margin-bottom: 15px;"/>
                <LanguageIcon :code="cat.toLanguage.code"
                              style="width: 55px; margin-bottom: 0px; margin-left: -20px; border: 4px solid white; border-radius: 50%;"

                />
              </div>
            </div>
            <div class="col-xl col-md col-sm-12 headng">
              <router-link
                  class=""
                  style="text-decoration: none; color: inherit;"
                  id=""
                  :to="{ name: 'CategoryList', params: { id: cat.id } }"
              >
                <h5 class="" style="font-weight: bold;">
                  {{ cat.fromLanguage.name }}-{{ cat.toLanguage.name }}
                  {{ $t("lCourse") }}
                </h5>
                <p>
                  {{ $t("curAt") }} {{ cat.course.category.name }} -
                  {{ cat.course.name }}
                </p>
              </router-link>
            </div>
          </div>
        </div>

        <div v-if="token" class="col"
             style="text-align: center; padding: 35px; margin-bottom: 30px; background: #fdfeff; border-radius: 7px; cursor: pointer; border: 2px dashed orange;">
          <b-button class="secButton" :to="{ name: 'Selection', params: {} }" style="padding: 8px 20px 8px 20px;">
            + {{ $t("addNew") }}
          </b-button>
        </div>

        <div v-else class="col"
             style="text-align: center; padding: 35px; margin-bottom: 150px; background: #fdfeff; border-radius: 7px; cursor: pointer; border: 2px dashed orange;">
          <b-button @click="$emit('login')" class="secButton" style="padding: 8px 20px 8px 20px;">
            Login to Start
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import LanguageIcon from "@/components/LanguageIcon.vue";

export default {
  name: "Category",
  data() {
    return {
      catList: [],
      progList: [],
      current: 0,
      case: 0,
    };
  },

  computed: {
    ...mapState({
      token: (state) => state.auth.token,
      account: (state) => state.auth.account,
      apiBase: (state) => state.global.apiBase,
      fileBase: (state) => state.global.fileBase,
      avatarBase: (state) => state.global.avatarBase,
    }),
  },

  async mounted() {
    this.getCategoryList();

    if (this.token) {
      this.getProgressList();
    }

  },

  methods: {
    //  ...mapActions(["getProfileG",]),

    getCategoryList() {
      fetch(this.apiBase + "/api/v1/categories", {
        method: "GET",
        redirect: "follow",
      })
          .then((response) => response.json())
          .then((result) => {
            this.catList = result.categories;
          })
          .catch((error) => console.log("error", error));
    },

    getProgressList() {

      fetch(
          this.apiBase + "/api/v1/learning/list?token=" + this.token,
          {
            method: "GET",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            this.progList = result.userProgressList;
          })
          .catch((error) => console.log("error", error));
    },
  },

  components: {LanguageIcon},
  props: {},

};
</script>

<style scoped>
.bg {
  background: #f7f8fc;
}

.secButton {
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  background: #eee1c4;
  border-color: #eee1c4;
  color: #f4a900;
}

.secButton:hover {
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  background: #f4a900;
  border-color: #f4a900;
  color: white;
}

.complete {
  border-left: 5px solid #48bf92 !important;
}

.headng:hover {
  color: #f4a900;
}
</style>
