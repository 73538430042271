<template>
<div class="" style="text-align: left;">
    <div class="container" style="padding-top: 50px; padding-bottom: 50px;">
        <span>
            <router-link class="" style="text-decoration: none;" id="" :to="{ name: 'Landing', params: {} }">
                <span class="">Home</span>
            </router-link> |
            <span style="color: #F5AD0B;">Alphabet</span>
        </span>
        <h1 style="font-weight: bold;">Alphabet</h1>
    </div>
    <div class="container-fluid bg" style="padding-top: 50px; padding-bottom: 50px;">
        <div class="container">
            <h4 style="margin-bottom: 40px; font-weight: 600;">English alphabet</h4>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo deleniti cumque odit, voluptatibus quisquam amet recusandae sequi nam in. Aspernatur voluptas vel corrupti illo deleniti consequatur reprehenderit vero quia odio.</p>
            <h4 style="margin-bottom: 40px; margin-top: 40px; font-weight: 600;">Pronounciation of letters</h4>
            <div class="row" style="background: white; border-radius: 10px; padding: 10px; margin-bottom: 20px;" v-for="(comp, i) in 15" :key="i">
                <div class="col-xl-2 col-md-2 col-sm-12" style="font-weight: 600; border-right: 2px solid lightgrey; text-align: center;" >
                    Aa <img src="@/assets/categoryIcons/voice.svg" style="padding: 5px;" alt=""> 
                </div>
                <div class="col-xl-2 col-md-2 col-sm-12" style="" >
                    Cat <img src="@/assets/categoryIcons/voice.svg" style="padding: 5px;" alt="">
                </div>
                <div class="col-xl-2 col-md-2 col-sm-12" style="" >
                    Bat <img src="@/assets/categoryIcons/voice.svg" style="padding: 5px;" alt="">
                </div>
                <div class="col-xl-2 col-md-2 col-sm-12" style="" >
                    Gas <img src="@/assets/categoryIcons/voice.svg" style="padding: 5px;" alt="">
                </div>
                <div class="col-xl-2 col-md-2 col-sm-12" style="" >
                    Caesar <img src="@/assets/categoryIcons/voice.svg" style="padding: 5px;" alt="">
                </div>
                <div class="col-xl-2 col-md-2 col-sm-12" style="" >
                    Jotaro <img src="@/assets/categoryIcons/voice.svg" style="padding: 5px;" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
  name: 'Alphabet',
  data(){
      return{
        cookiesObject: '',
        nname: '',
        fname: '',
        number: '',
        country: '',
        birth: '',
        gender: '',
        }
  },
  async mounted(){
   
  },

  methods : {
     
  },

  components: {

  },
  props: {

  },
      
  computed: {

  },
}

</script>

<style scoped>
.bg{
    background: #F7F8FC;
}

</style>
