export default {
    namespaced: true,
    actions: {
        setLanguages({commit}, languages) {
            commit('setLanguages', languages);
        },
        setSettings({commit}, settings) {
            commit('setSettings', settings);
        }
    },
    mutations: {
        setLanguages(state, languages) {
            state.languages = languages;
        },
        setSettings(state, settings) {
            state.settings = settings;
        }
    },
    state: {
        applicationKey: 'uz_zunilingo',
        ssoBase: 'https://sso.zunilingo.com',
        apiBase: 'https://api.zunilingo.com',
        fileBase: "https://static.zuniac.com/language/",
        avatarBase: "https://static.zuniac.com/avatar/",
        languages: [],
        settings: {}
    },
    getters: {
        ssoBase: (state) => state.ssoBase,
        apiBase: (state) => state.apiBase,
    },
}