<template>
<div class="bg">
    <div class="container" style="padding-top: 80px; padding-bottom: 80px;">
        <img src="@/assets/fail.svg" style="margin-bottom: 50px; margin-top: 30px;"  alt="">
        <h1 style="font-weight: bold;">Server is currently down</h1>
        <p>The problem is temporary. We will solve this problem soon. Please check again shortly.</p>
        <b-button class="mainButton" to="/PracticePage" target="_blank" style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-bottom: 50px; margin-top: 50px;"> Home page </b-button>
    </div>
</div> 
</template>

<script>

export default {
  name: 'Fail',
  data(){
      return{
        cookiesObject: '',
        name: '',
        selected: '',
        }
  },
  async mounted(){
   
  },

  methods : {
     
  },

  components: {

  },
  props: {

  },
      
  computed: {

  },
}

</script>

<style scoped>
.bg{
    background: #F7F8FC;
}

</style>
