<template>
  <div>
    <div
        class="container"
        style="min-height: 72vh; padding-top: 30px;"
        v-if="show == 1"
    >
      <div class="" style="">
        <div class="col">
          <div style="">
            <div
                v-if="question"
                class="col-xl-7 col-md-7 col-sm-12"
                style="margin: auto;"
            >
              <div v-if="question.type == 'photo'">
                <img
                    class="col"
                    :src="
                    'https://static.zuniac.com/language/photo/' +
                      question.content
                  "
                    style=""
                    alt=""
                />
                <h5 style="margin-top: 15px;">
                  What is presented on the image?
                </h5>
              </div>
              <div v-else-if="question.type == 'audio'">
                <audio
                    class="col"
                    :key="question.content"
                    autoplay
                    controls
                    style="border-radius: 10px;"
                    controlsList="nodownload"
                >
                  <source
                      v-html="question.content"
                      :src="
                      'https://static.zuniac.com/language/audio/' +
                        question.content
                    "
                  />
                </audio>
                <h5 style="margin-top: 15px;">
                  Translate word/phrase from recording
                </h5>
              </div>
              <h2 v-else-if="question.type == 'text'">
                Translate: {{ question.content }}
              </h2>
            </div>
          </div>
          <div
              v-if="question"
              class="row"
              style="text-align: left; margin-top: 80px;"
          >
            <div
                class="col-xl-6 col-md-6 col-sm-12 "
                style=" margin-bottom: 10px;"
            >
              <div
                  class="banner row mr-1 ml-1"
                  style="padding: 20px; border-radius: 7px; background: #FEFEFE; border-bottom: 3px solid #E8EAEF;"
                  v-bind:class="{
                  correct: selected == 1 && returned == 1,
                  false: selected == 1 && returned == 2,
                }"
              >
                <div class="col-1 mr-1">
                  <span class="numb">1</span>
                </div>
                <button
                    :disabled="returned !== 0"
                    @click="
                    answerExam(question.answers[0].keyword, question.id),
                      (selected = 1)
                  "
                    class="col"
                    style="font-weight: bold; background: none; border: none; text-align: left;"
                >
                  {{ question.answers[0].content }}
                </button>
              </div>
            </div>
            <div
                class="col-xl-6 col-md-6 col-sm-12 "
                style=" margin-bottom: 10px;"
            >
              <div
                  class="banner row mr-1 ml-1"
                  style="padding: 20px; border-radius: 7px; background: #FEFEFE; border-bottom: 3px solid #E8EAEF;"
                  v-bind:class="{
                  correct: selected == 2 && returned == 1,
                  false: selected == 2 && returned == 2,
                }"
              >
                <div class="col-1 mr-1">
                  <span class="numb">2</span>
                </div>
                <button
                    :disabled="returned !== 0"
                    @click="
                    answerExam(question.answers[1].keyword, question.id),
                      (selected = 2)
                  "
                    class="col"
                    style="font-weight: bold; background: none; border: none; text-align: left;"
                >
                  {{ question.answers[1].content }}
                </button>
              </div>
            </div>
            <div
                class="col-xl-6 col-md-6 col-sm-12 "
                style=" margin-bottom: 10px;"
            >
              <div
                  class="banner row mr-1 ml-1"
                  style="padding: 20px; border-radius: 7px; background: #FEFEFE; border-bottom: 3px solid #E8EAEF;"
                  v-bind:class="{
                  correct: selected == 3 && returned == 1,
                  false: selected == 3 && returned == 2,
                }"
              >
                <div class="col-1 mr-1">
                  <span class="numb">3</span>
                </div>
                <button
                    :disabled="returned !== 0"
                    @click="
                    answerExam(question.answers[2].keyword, question.id),
                      (selected = 3)
                  "
                    class="col"
                    style="font-weight: bold; background: none; border: none; text-align: left;"
                >
                  {{ question.answers[2].content }}
                </button>
              </div>
            </div>
            <div
                class="col-xl-6 col-md-6 col-sm-12 "
                style=" margin-bottom: 10px;"
            >
              <div
                  class="banner row mr-1 ml-1"
                  style="padding: 20px; border-radius: 7px; background: #FEFEFE; border-bottom: 3px solid #E8EAEF;"
                  v-bind:class="{
                  correct: selected == 4 && returned == 1,
                  false: selected == 4 && returned == 2,
                }"
              >
                <div class="col-1 mr-1">
                  <span class="numb">4</span>
                </div>
                <button
                    :disabled="returned !== 0"
                    @click="
                    answerExam(question.answers[3].keyword, question.id),
                      (selected = 4)
                  "
                    class="col"
                    style="font-weight: bold; background: none; border: none; text-align: left;"
                >
                  {{ question.answers[3].content }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5
          v-if="returned == 1"
          style="font-weight: bold; color: #48bf92; margin-top: 15px;"
      >
        {{ $t("correct") }}, +10 Points!
      </h5>
      <h5
          v-else-if="returned == 2"
          style="font-weight: bold; color: #cb5e77; margin-top: 15px;"
      >
        {{ $t("wrong") }}, no Points!
      </h5>
      <b-button
          v-if="returned > 0"
          class="mainButton"
          @click="nextQuest"
          style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-top: 20px;"
      >
        {{ $t("nQuest") }}
      </b-button>
    </div>

    <!-- <b-modal size="md" ref="nextcourse" id="nextcourse" hide-footer hide-header body-class="loginModal">
        <div style="text-align: right; margin-bottom: -30px; margin-right: -30px;">
            <img src="@/assets/closeButt.svg" @click="hideModal" style="cursor: pointer;" alt="">
        </div>
        <div class="d-block text-center">
            <h4 style="margin-top: 15px; margin-bottom: 15px;">Summary test is finished. </h4> <br> 
            <p> <span v-if="success"> Congratulations! </span> You earned {{tpoints}} points</p> <br> 
            <h5 style="margin-bottom: 15px; padding-bottom: 10px;">
                <span v-if="nextStep == 'retry_course_exam'" style="text-decoration: underline; cursor: pointer;" @click="retry()">Unfortunately points are not enough to move on. But u can try again!</span>
                <span v-else-if="nextStep == 'retry_general'" style="text-decoration: underline; cursor: pointer;" >Unfortunately points are not enough to move on. But u can start general exam again! </span>
                <span v-else-if="nextStep == 'retry_category_exam'" style="text-decoration: underline; cursor: pointer;" >Unfortunately points are not enough to move on. But u can start category exam again! </span>
                <span v-else-if="nextStep == 'back'" style="text-decoration: underline; cursor: pointer;" @click="progressPage()">Return to progress page! </span>
                <span v-else-if="nextStep == 'category_exam'" style="text-decoration: underline; cursor: pointer;">You are ready to take category Exam! </span>
                <span v-else-if="nextStep == 'next_course'" style="text-decoration: underline; cursor: pointer;" @click="nextCourse()">You can start next course! </span>
                <span v-else-if="nextStep == 'final_exam'" style="text-decoration: underline; cursor: pointer;" > You can now start final exam! </span>
                <span v-else style="text-decoration: underline; cursor: pointer;" @click="progressPage()"> Test finished! View my progress </span>
            </h5>
        </div>
    </b-modal> -->

    <div v-else class="container" style="min-height: 72vh; padding-top: 50px;">
      <h3
          style="margin-top: 15px; margin-bottom: 15px; font-weight: bold; color: #1c50af;"
      >
        {{ $t("tFinished") }}
      </h3>
      <br/>
      <h4>
        <span v-if="success"> Congratulations, </span> {{ $t("earn") }}
        <span style="font-weight: bold; font-size: 25px;">{{ tpoints }}</span>
        {{ $t("points") }}!
      </h4>
      <br/>
      <div style="margin-bottom: 15px; padding-bottom: 10px;">
        <div v-if="nextStep == 'retry_course_exam'">
          <p>{{ $t("un1") }}</p>
          <div class="row">
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                @click="retry()"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #48BF92; border: none;"
            >{{ $t("rt") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{ name: 'CategoryList', params: { id: this.progressId } }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900; border: none;"
            >{{ $t("gpp") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{
                name: 'CourseMaterial',
                params: {
                  progressId: this.progressId,
                  courseId: this.courseId,
                  catId: this.catId,
                },
              }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900;; border: none;"
            >{{ $t("gcm") }}
            </b-button
            >
          </div>
        </div>
        <div v-else-if="nextStep == 'retry_general'">
          <p>{{ $t("un2") }}</p>
          <div class="row">
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                @click="retry()"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #48BF92; border: none;"
            >{{ $t("rt") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{ name: 'CategoryList', params: { id: this.progressId } }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900; border: none;"
            >{{ $t("gpp") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{
                name: 'CourseMaterial',
                params: {
                  progressId: this.progressId,
                  courseId: this.courseId,
                  catId: this.catId,
                },
              }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900;; border: none;"
            >{{ $t("gcm") }}
            </b-button
            >
          </div>
        </div>
        <div v-else-if="nextStep == 'retry_category_exam'">
          <p>{{ $t("un3") }}</p>
          <div class="row">
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                @click="retry()"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #48BF92; border: none;"
            >{{ $t("rt") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{ name: 'CategoryList', params: { id: this.progressId } }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900; border: none;"
            >{{ $t("gpp") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{
                name: 'CourseMaterial',
                params: {
                  progressId: this.progressId,
                  courseId: this.courseId,
                  catId: this.catId,
                },
              }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900;; border: none;"
            >{{ $t("gcm") }}
            </b-button
            >
          </div>
        </div>
        <div v-else-if="nextStep == 'back'">
          <p style="margin-bottom: 50px;">{{ $t("rpp") }}</p>
          <div class="container">
            <div class="row">
              <b-button
                  class="col-md-5 col-sm-12 m-auto"
                  :to="{ name: 'CategoryList', params: { id: this.progressId } }"
                  style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900; border: none;"
              >{{ $t("gpp") }}
              </b-button
              >
            </div>
          </div>
        </div>
        <div v-else-if="nextStep == 'category_exam'">
          <p>{{ $t("tce") }}</p>
          <div class="row">
            <b-button
                :to="{
                name: 'Practice',
                params: { id: progressId, courseId: 0 },
              }"
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #48BF92; border: none;"
            >{{ $t("sce") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{ name: 'CategoryList', params: { id: this.progressId } }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900; border: none;"
            >{{ $t("gpp") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{
                name: 'CourseMaterial',
                params: {
                  progressId: this.progressId,
                  courseId: this.courseId,
                  catId: this.catId,
                },
              }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900;; border: none;"
            >{{ $t("gcm") }}
            </b-button
            >
          </div>
        </div>
        <div v-else-if="nextStep == 'next_course'">
          <p>{{ $t("snc") }}</p>
          <div class="row">
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                @click="nextCourse()"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #48BF92; border: none;"
            >{{ $t("snc") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{ name: 'CategoryList', params: { id: this.progressId } }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900; border: none;"
            >{{ $t("gpp") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{
                name: 'CourseMaterial',
                params: {
                  progressId: this.progressId,
                  courseId: this.courseId,
                  catId: this.catId,
                },
              }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900;; border: none;"
            >{{ $t("gcm") }}
            </b-button
            >
          </div>
        </div>
        <div v-else-if="nextStep == 'final_exam'">
          <p>{{ $t("tfe") }}</p>
          <div class="row">
            <b-button   :to="{
                name: 'Practice',
                params: { id: progressId, courseId: 0 , catId: 0},
              }"
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #48BF92; border: none;"
            >{{ $t("sfe") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{ name: 'CategoryList', params: { id: this.progressId } }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900; border: none;"
            >{{ $t("gpp") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{
                name: 'CourseMaterial',
                params: {
                  progressId: this.progressId,
                  courseId: this.courseId,
                  catId: this.catId,
                },
              }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900;; border: none;"
            >{{ $t("gcm") }}
            </b-button
            >
          </div>
        </div>
        <div v-else>
          <p>{{ $t("err") }}</p>
          <div class="row">
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{ name: 'CategoryList', params: { id: this.progressId } }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900; border: none;"
            >{{ $t("gpp") }}
            </b-button
            >
            <b-button
                class="col-md col-sm-12 ml-5 mr-5 mt-3"
                :to="{
                name: 'CourseMaterial',
                params: {
                  progressId: this.progressId,
                  courseId: this.courseId,
                  catId: this.catId,
                },
              }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #f4a900;; border: none;"
            >{{ $t("gcm") }}
            </b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Practice",
  data() {
    return {
      progressId: 0,
      courseId: 0,
      catId: 0,
      selected: 0,
      questions: [],
      current: 0,
      returned: 0,
      final: false,
      show: 1,
      tpoints: 0,
      success: Boolean,
      nextStep: Text,
      nextStepId: Number,
    };
  },
  async mounted() {
    this.progressId = this.$route.params.progressId || 0;
    this.courseId = this.$route.params.courseId || 0;
    this.catId = this.$route.params.catId || 0;

    if (this.$route.params.progressId && this.courseId > 0) {
      this.startCourseExam();
    } else if (this.$route.params.progressId && this.catId > 0) {
      this.startCategoryExam();
    } else if (
        this.$route.params.progressId &&
        this.courseId  < 1&&
        this.catId < 1
    ) {
      this.startFinalExam();
    } else {
      alert("Error");
    }
  },
  methods: {
    startCourseExam() {
      fetch(
          this.apiBase + "/api/v1/startExam?courseId=" + this.courseId + "&token=" + this.token + "&userProgressId=" + this.progressId,
          {
            method: "GET",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            this.questions = result.questions;
            console.log(this.questions);
          })
          .catch((error) => console.log("error", error));
    },
    startCategoryExam() {
      fetch(
          this.apiBase + "/api/v1/startExam?categoryId=" + this.catId + "&token=" + this.token + "&userProgressId=" + this.progressId,
          {
            method: "GET",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            this.questions = result.questions;
          })
          .catch((error) => console.log("error", error));
    },
    startFinalExam() {
      fetch(
          this.apiBase + "/api/v1/startExam?token=" + this.token + "&userProgressId=" + this.progressId,
          {
            method: "GET",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            if(result.code===404){

              let realname = prompt("Please enter your name for final certificate", "");

              if(realname && realname.trim()!== ""){

                fetch(
                    this.apiBase + "/api/v1/setRealName?token=" + this.token + "&name=" + realname,
                    {
                      method: "POST",
                      redirect: "follow",
                    }
                )
                    .then((response) => response.json())
                    .then((result) => {
                      if (result.code === 200) {
                        alert("Your name has been saved successfully");
                        this.startFinalExam();
                      } else {
                        this.errorText = result.message;
                      }
                    })
                    .catch((error) => console.log("error", error));

              }

            } else {
              this.questions = result.questions;
              this.show = 1;
            }
          })
          .catch((error) => console.log("error", error));
    },
    answerExam(val1, val2) {

      fetch(
          this.apiBase + "/api/v1/finishExam?answer=" + val1 + "&questionId=" + val2 + "&token=" + this.token,
          {
            method: "POST",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.correct == true) {
              this.returned = 1;
            } else {
              this.returned = 2;
            }

            console.log(result);

            if (result.final == true) {
              this.tpoints = result.totalPoint;
              this.success = result.success;
              this.nextStep = result.nextStep;
              this.nextStepId = result.nextStepId;
              this.final = true;
              // this.openModal();
            }
          })
          .catch((error) => console.log("error", error));
    },
    nextQuest() {
      this.current++;
      this.returned = 0;
      if (this.final === true) {
        this.show = 2;
      }
      // if (this.current == this.exam.length) {
      //     this.openModal();
      // }
    },
    retry() {
      location.reload();
    },
    hideModal() {
      this.$refs["nextcourse"].hide();
    },

    openModal() {
      this.$refs["nextcourse"].show();
    },
    nextCourse() {
      this.$router.push({
        name: "CourseMaterial",
        params: {
          progressId: this.progressId,
          courseId: this.nextStepId,
          catId: this.catId,
        },
      });
    },
    progressPage() {
      this.$router.push({
        name: "CategoryList",
        params: {id: this.progressId},
      });
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapState({
      apiBase: (state) => state.global.apiBase,
      fileBase: (state) => state.global.fileBase,
      token: state => state.auth.token
    }),
    question() {
      return this.questions[this.current];
    },
  },
};
</script>

<style scoped>
.numb {
  padding: 5px 8px 5px 8px;
  background: lightgrey;
  border-radius: 50%;
  margin-left: 4px;
  text-align: center;
}

.click {
  background: grey;
  color: black;
}

.correct {
  background: #48bf92 !important;
  color: white;
}

.color {
  color: green;
}

.false {
  background: #cb5e77 !important;
  color: white;
}
</style>
