<template>
<div class="container-fluid" style="padding-top: 70px; padding-bottom: 70px; background: #F7F8FC;">
    <div class="container">
        <div>
            <h1 style="font-weight: bold; margin-bottom: 50px;">{{ $t('wiki') }}</h1>
            <p>{{ $t('wikiText') }}</p>
        </div>
        <div class="row">
            <div class="col-xl-4 col-md-4 col-sm-12" style="padding-top: 70px;">
                <img src="@/assets/wiki/one.svg" alt=""> 
                <div style="border-radius: 50%; background :#F4A900; height: 10px; width: 10px; margin: auto; margin-top: 20px; margin-bottom: 20px;"></div>
                <h4 style="font-weight: 600;">{{ $t('Sone') }}</h4>
                <p>{{ $t('SoneText') }}</p>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-12" style="padding-top: 70px;">
                <img src="@/assets/wiki/two.svg" alt="">
                <div style="border-radius: 50%; background :#F4A900; height: 10px; width: 10px; margin: auto; margin-top: 20px; margin-bottom: 20px;"></div>
                <h4 style="font-weight: 600;">{{ $t('Stwo') }}</h4>
                <p>{{ $t('StwoText') }}</p>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-12" style="padding-top: 70px;">
                <img src="@/assets/wiki/three.svg" alt="">
                <div style="border-radius: 50%; background :#F4A900; height: 10px; width: 10px; margin: auto; margin-top: 20px; margin-bottom: 20px;"></div>
                <h4 style="font-weight: 600;">{{ $t('Sthree') }}</h4>
                <p>{{ $t('SthreeText') }}</p>
            </div>
        </div>
        <b-button class="mainButton" id="arrow"
                  @click.prevent="$emit('login')"
                  style="font-weight: 600; border-radius: 5px; font-size: 15px; margin-bottom: 30px; padding: 8px 20px 8px 20px; margin-top: 50px;"> {{ $t('learn') }} <img src="@/assets/arrow.svg" id="arrow1" alt=""></b-button>
    </div>
</div> 
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Wiki',
  data(){
      return{
        }
  },
  async mounted(){
   
  },

  methods : {
     
  },

  components: {

  },
  props: {

  },
      
 computed: {
      ...mapGetters(["cookiesObject", "httpReq", "apiReq"]),
  },
}

</script>

<style scoped>



</style>
