<template>
  <div class="" style="text-align: left;" v-if="progressData">
    <div class="container" style="padding-top: 50px; padding-bottom: 50px;">
      <span>
        <router-link
            class=""
            style="text-decoration: none;"
            id=""
            :to="{ name: 'Landing', params: {} }"
        >
          <span class="">Home</span>
        </router-link>
        |
        <span style="color: #F5AD0B;">Learning</span>
      </span>
      <div class="row">
        <div class="col-xl-7 col-md-7 col-sm-12" style="margin-top: 50px;">
          <h1 style="font-weight: bold; padding-top: 30px;">
            {{ progressData.fromLanguage.name }}-{{ progressData.toLanguage.name }}
            {{ $t("lCourse") }}
          </h1>
          <p>{{ $t("mCourseText") }}</p>
        </div>
        <div
            class="col-xl col-md col-sm-12"
            style="margin-top: 50px; text-align: center;"
        >
          <div class="row">
            <div class="col-md-6 col-xl-6 col-sm-12">
              <div
                  style="background: #fcfcfc; padding: 25px; margin-bottom: 15px; border-radius: 7px;"
              >
                <small>{{ $t("speak") }}</small>
                <h4 class="font-weight-bold text-truncate ">

                  <LanguageIcon :code="progressData.fromLanguage.code" style="width: 25px;"/>
                  {{ progressData.fromLanguage.name }}
                </h4>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-sm-12">
              <div
                  style="background: #fcfcfc; padding: 25px; margin-bottom: 15px; border-radius: 7px;"
              >
                <small>{{ $t("learn") }}</small>
                <h4 v-if="progressData.fromLanguage" class="font-weight-bold text-truncate">
                  <LanguageIcon :code="progressData.toLanguage.code" style="width: 25px;"/>

                  {{ progressData.toLanguage.name }}
                </h4>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-sm-12">
              <div
                  style="background: #fcfcfc; padding: 25px; margin-bottom: 15px; border-radius: 7px;"
              >
                <small>{{ $t("started") }}</small>
                <h4 v-if="progressData.startTime" style="font-weight: 550;">
                  {{ progressData.startTime | moment("DD.MM.YYYY") }}
                </h4>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-sm-12">
              <div
                  style="background: #fcfcfc; padding: 25px; margin-bottom: 15px; border-radius: 7px;"
              >
                <small>{{ $t("finished") }}</small>
                <h4 v-if="progressData.endTime" style="font-weight: 550;">
                  {{ progressData.endTime | moment("DD.MM.YYYY") }}
                </h4>
                <h4 v-else style="font-weight: 550;">In progress</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="container mb-5" v-if="progressData.certificate">
      <img :src="`/certificates/${progressData.certificate}`" class="w-100 img-thumbnail" alt="certificate">
    </div>

    <div class="container mb-5">
      <div
          v-for="(cat, i) in categoryList"
          :key="cat.id"
          style="border-left: 5px solid #E8EAEF;"
          class="d-flex pb-5"
          :class="{ complete: cat.listOrder < currentCategoryProgress }"
      >
        <div style="width: 20px;">
          <img
              v-if="cat.listOrder < currentCategoryProgress"
              src="@/assets/check1.svg"
              style="margin-left: -10px; margin-top: -9px;"
              alt=""
          />
          <div
              v-else
              style="height: 28px; width: 28px; background: #E8EAEF; border-radius: 50%; margin-left: 4px; text-align: center;"
          >
            {{ i + 1 }}
          </div>
        </div>
        <div class="w-100">
          <div
              style=" border-radius: 10px; cursor: pointer"
              :style="{
                'margin-left':cat.listOrder === currentCategoryProgress ? '20px' : '40px',
                'background': cat.listOrder === currentCategoryProgress ? '#FFF' : '#eee'
              }"
              :class="cat.listOrder===currentCategoryProgress?'p-5':'p-4'"
              class="shadow" @click="showCategoryCourses(cat)"
          >
            <div class="d-lg-flex justify-content-between align-items-center">
              <div :class="currentCategoryProgress===cat.listOrder?'h4':'h5'" class="mb-0 pb-0">
                {{ cat.name }}
              </div>
              <img src="@/assets/categoryIcons/progress.svg" alt="" v-if="cat.id === currentCategoryProgress">
            </div>
            <p class="mt-2" v-if="cat.description.trim()">
              {{ cat.description }}
            </p>
            <b-button
                v-if="cat.listOrder === currentCategoryProgress"
                class="secButton mt-3"
            >
              {{ $t("continue") }}
            </b-button>

          </div>
          <div :id="'collapse-' + cat.id" class="mt-3 ml-5" v-if="openCategories.indexOf(cat.id)>-1">

            <div class="row" v-if="courseList[`c${cat.id}`]">
              <div
                  v-for="course in courseList[`c${cat.id}`]" :key="course.id"
                  class="col-md-4 col-sm-12 mb-3"
              >
                <div class="p-3 rounded shadow "
                     :style="{'cursor': cat.listOrder > progressData.category.listOrder || ( cat.listOrder === progressData.category.listOrder && course.listOrder > progressData.course.listOrder)?'not-allowed':'pointer'}"
                     @click="courseClick(course)"
                >
                  <span
                      :class="{'text-secondary': cat.listOrder > progressData.category.listOrder || ( cat.listOrder === progressData.category.listOrder && course.listOrder > progressData.course.listOrder)}"
                  >
                    {{ course.name }}
                  </span>

                  <div class="mt-2"
                       v-if="course.id === progressData.course.id">
                    <b-button
                        size="small" class="secButton"
                        :to="{
                          name: 'CourseMaterial',
                          params: {
                            progressId: id,
                            courseId: course.id,
                            catId: course.category.id,
                          },
                    }"
                        style="margin-bottom: 0px; margin-top: 0px;"
                    >
                      {{ $t("continue") }}
                    </b-button>
                  </div>

                </div>
              </div>
            </div>
            <div v-else style="height: 300px;" class="d-flex text-center align-items-center">
              Loading...
            </div>
          </div>
        </div>
      </div>
      <!-- <b-button v-if="progList[0].course.id == cat.id" class="mainButton mr-4 ml-4" :to="{ name: 'CourseMaterial', params: { id: id, courseId: courseId} }"  style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-top: 20px;"> Continue </b-button> -->

    </div>

    <div class="container d-none">
      <h3 style="font-weight: 600; margin-bottom: 30px;">
        {{ $t("startGen") }}
      </h3>
      <div
          class="row pt-4 pb-4"
          style="background: #fcfcfc; border-radius: 10px;"
      >
        <div class="col-xl-3 col-md-3 col-sm-12">
          <img src="@/assets/testIcon.svg" style="" alt=""/>
        </div>
        <div class="col-xl-9 col-md-9 col-sm-12">
          <h3 style="font-weight: 600;">{{ $t("onMix") }}</h3>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque
            dolore, doloremque suscipit facere enim necessitatibus, pariatur
            asperiores debitis inventore tenetur ipsam consectetur nihil
            deserunt fugiat. In ab repellat suscipit esse.
          </p>
          <b-button
              class="mainButton"
              id="arrow"
              :to="{
              name: 'Practice',
              params: { progressId: this.id, courseId: 0, catId: 0 },
            }"
              style="font-weight: 600; border-radius: 5px; font-size: 15px; margin-bottom: 15px;"
          >
            {{ $t("sNow") }} <img src="@/assets/arrow.svg" id="arrow1" alt=""
          /></b-button>
        </div>
      </div>
      <h3 style="font-weight: 600; margin-bottom: 30px; margin-top: 30px;">
        {{ $t("alphabet") }}
      </h3>
      <div
          class="row pt-4 pb-4"
          style="background: #fcfcfc; border-radius: 10px; margin-bottom: 20px;"
      >
        <div class="col-xl-3 col-md-3 col-sm-12">
          <img src="@/assets/alphaIcon.svg" style="" alt=""/>
        </div>
        <div class="col-xl-9 col-md-9 col-sm-12">
          <h3 style="font-weight: 600;">{{ $t("sTuned") }}</h3>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque
            dolore, doloremque suscipit facere enim necessitatibus, pariatur
            asperiores debitis inventore tenetur ipsam consectetur nihil
            deserunt fugiat. In ab repellat suscipit esse.
          </p>
          <b-button
              class="mainButton"
              id="arrow"
              to="/Alphabet"
              style="font-weight: 600; border-radius: 5px; font-size: 15px; margin-bottom: 15px;"
          >
            {{ $t("sNow") }} <img src="@/assets/arrow.svg" id="arrow1" alt=""
          /></b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import LanguageIcon from "@/components/LanguageIcon.vue";

export default {
  name: "CategoryList",
  data() {
    return {
      id: "",
      categoryList: [],
      courseList: {},
      loader: null,
      curr: null,
      progressData: null,
      openCategories: []
    };
  },

  computed: {
    ...mapState({
      apiBase: (state) => state.global.apiBase,
      token: state => state.auth.token
    }),
    currentCategoryProgress() {
      return this.progressData.category.listOrder;
    }
  },

  async mounted() {
    this.id = this.$route.params.id;

    this.getCategoryList();
    // this.getProgressList();
    this.getProgressData();
  },

  methods: {

    async showCategoryCourses(category) {
      if (!this.openCategories.includes(category.id)) {
        await this.getCourseList(category);
        this.openCategories.push(category.id);
      } else {
        this.openCategories.splice(this.openCategories.indexOf(category.id), 1);
      }
    },


    getCategoryList() {

      fetch(this.apiBase + "/api/v1/categories", {
        method: "GET",
        redirect: "follow",
      })
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              this.categoryList = result.categories;
            }
          })
          .catch((error) => console.log("error", error));
    },

    getCourseList(category) {
      this.loader = true;

      fetch(
          this.apiBase + "/api/v1/category/" + category.id + "/courses",
          {
            method: "GET",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              category.courseList = result.courseList;
              this.$set(this.courseList, `c${category.id}`, result.courseList);
              //this.courseList[`c${category.id}`] = result.courseList;
              this.$root.$emit("bv::toggle::collapse", `collapse-${category.id}`);
            } else {
              this.courseList[`c${category.id}`] = [];
            }
          })
          .catch((error) => console.log("error", error));
    },

    getProgressData() {

      fetch(
          this.apiBase +
          "/api/v1/learning/" +
          this.id +
          "?token=" +
          this.token,
          {
            method: "GET",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            this.progressData = result.userProgress;
            //todo check this.current = result.userProgress.category.id;
            console.log(result);
          })
          .catch((error) => console.log("error", error));
    },

    courseClick(course){

      if(course.category.listOrder < this.progressData.category.listOrder || ( course.category.listOrder === this.progressData.category.listOrder && course.listOrder <= this.progressData.course.listOrder)){
        this.$router.push({
          name: 'CourseMaterial',
          params: {
            progressId: this.progressData.id,
            courseId: course.id,
            catId: course.category.id,
          },
        })
      } 
    }
  },

  components: {LanguageIcon},
  props: {},

};
</script>

<style scoped>
.bg {
  background: #f7f8fc;
}

.active {
  border: 2px solid #48bf92 !important;
}

.secButton {
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  background: #eee1c4;
  border-color: #eee1c4;
  color: #f4a900;
}

.secButton:hover {
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  background: #f4a900;
  border-color: #f4a900;
  color: white;
}

.complete {
  border-left: 5px solid #48bf92 !important;
}
</style>
