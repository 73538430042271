<template>
  <div class="" style="text-align: left;">
    <div class="container" style="padding-top: 50px; padding-bottom: 50px;">
      <span>
        <router-link
            class=""
            style="text-decoration: none;"
            id=""
            :to="{ name: 'Landing', params: {} }"
        >
          <span class="">Home</span>
        </router-link>
        |
        <span style="color: #F5AD0B;">Profile</span>
      </span>
      <h1 style="font-weight: bold;">{{ $t("edit") }}</h1>
    </div>
    <div class="container-fluid" style="background: #F7F8FC;" v-if="account">
      <div class="container pt-3">
        <div class="row">

          <div class=" col-md-6">
            <form @submit.prevent="setUsername">

            <p style="margin-top: 15px;">{{ $t("eUname") }}</p>
            <b-form-input
                id="input-name"
                v-model="newName"
                placeholder="Your nickname"
                maxlength="12"
                class="mb-3"
            ></b-form-input>

            <b-button
                type="submit"
                class="mainButton"
                style="font-weight: 600; border-radius: 5px; font-size: 15px; margin-bottom: 15px;"
            >
              {{ $t("sChanges") }}
            </b-button>
            </form>
            <p class="text-danger">{{ errorText }}</p>
          </div>

          <div class="col-md-6 mb-3">
            <h4>{{ $t("yAvatar") }}</h4>
            <div class="mb-3">
              <img v-if="account.avatar" :src="avatarBase + account.avatar" alt=""
                   style="margin-top: 20px; border-radius: 50%;"
              />
              <img v-else src="@/assets/profile.svg"
                   style="width: 150px; background-color: #F4A900; border-radius: 50%;" alt=""
              />
            </div>

            <b-button class="mainButton" v-b-modal.avatar-modal
                      style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; border: 1px solid #F4A900; margin-right: 10px; margin-top: 10px;">
              {{ $t("chooseAv") }}
            </b-button>

          </div>
        </div>
      </div>
    </div>
    <b-modal
        size="lg"
        ref="avatar-modal"
        centered
        id="avatar-modal"
        hide-footer
        hide-header
        body-class="loginModal"
    >
      <div
          style="text-align: right; margin-bottom: -30px; margin-right: -30px;"
      >
        <img
            src="@/assets/closeButt.svg"
            @click="avatarModalHide()"
            style="cursor: pointer;"
            alt=""
        />
      </div>
      <div class="d-block text-center">
        <h4
            style="text-align: center; margin-top: -40px; font-weight: bold; color: #1E2E4D;"
        >
          Choose an Avatar!
        </h4>
        <div class="row">
          <div
              class="col-xl-3 col-md-3 col-sm-12 "
              v-for="avatar in avatarList"
              :key="avatar.id"
          >
            <img
                :src="avatarBase + avatar.path"
                alt=""
                style="margin-top: 20px; cursor: pointer;"
                @click="setAvatar(avatar.id)"
            />
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
        size="md"
        ref="nname-modal"
        @hide="handleOk"
        centered
        id="nname-modal"
        hide-footer
        hide-header
        body-class="loginModal"
    >
      <!-- <div style="text-align: right; margin-bottom: -30px; margin-right: -30px;">
            <img src="@/assets/closeButt.svg" @click="nnameModalHide()" style="cursor: pointer;" alt="">
        </div> -->
      <div class="d-block text-center">
        <h4
            style="text-align: center; margin-top: 0px; font-weight: bold; color: #1E2E4D;"
        >
          Before we continue, lets set your nickname first!
        </h4>
        <div class="row" style="margin-bottom: 20px;">
          <b-form-input
              id="input-name"
              v-model="newName"
              placeholder="Choose your username"
              @keydown.enter.native="setUsername()"
              maxlength="12"
              style="margin-top: 30px; margin-bottom: 30px;"
          ></b-form-input>
          <b-button
              @click="setUsername()"
              class="mainButton m-auto"
              style="font-weight: 600; border-radius: 5px; font-size: 15px;"
          >
            Save Changes
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Profile",
  data() {
    return {
      avatarList: [],
      newName: "",
      fname: "",
      errorText: null,
    };
  },

  computed: {
    ...mapState({
      apiBase: (state) => state.global.apiBase,
      ssoBase: (state) => state.global.ssoBase,
      avatarBase: (state) => state.global.avatarBase,
      token: (state) => state.auth.token,
      account: (state) => state.auth.account,
    }),
  },
  mounted() {
    if(this.token){
      this.getAvatars();
    }
    if(this.account){
      this.newName = this.account.username;
    }
    setTimeout(() => {
      if (!this.account.username) {
        this.nnameModalShow();
      }
    }, 500);
  },
  watch:{
    token(){
      this.getAvatars();
    },
    "account.username"(){
      this.newName = this.account.username;
    }
  },
  methods: {
    avatarModalHide() {
      this.$bvModal.hide("avatar-modal");
    },
    nnameModalHide() {
      this.$refs["nname-modal"].hide();
    },
    nnameModalShow() {
      this.$refs["nname-modal"].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    getAvatars() {
      fetch(
          this.ssoBase + "/avatars?token=" + this.token,
          {
            method: "GET",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            this.avatarList = result.avatars;
          })
          .catch((error) => console.log("error", error));
    },

    setAvatar(id) {
      fetch(
          this.ssoBase + "/avatar?avatarId=" + id + "&token=" + this.token,
          {
            method: "PUT",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            console.log(result.code);
            if (result.code === 200) {
              this.$emit('profileUpdated',true);

              this.avatarUpdatedSuccess();
              this.avatarModalHide();
            }
          })
          .catch((error) => console.log("error", error));
    },
    setUsername() {

      fetch(
          this.ssoBase + "/account/username?token=" + this.token + "&username=" + this.newName,
          {
            method: "PUT",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              this.$emit('profileUpdated',true);
              this.profileUpdatedSuccess();
            } else {
              this.errorText = result.message;
            }
          })
          .catch((error) => console.log("error", error));
    },
  },
  notifications:{
    profileUpdatedSuccess:{
      title: 'Profile Updated',
      message: 'Your profile has been updated successfully',
      type: 'success'
    },
    avatarUpdatedSuccess:{
      title: 'Avatar Updated',
      message: 'Your avatar updated successfully',
      type: 'success'
    },
  },
  components: {},
  props: {},

};
</script>

<style scoped>
.bg {
  background: #f7f8fc;
}
</style>
