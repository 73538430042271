var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"container",staticStyle:{"padding-top":"50px","padding-bottom":"50px"}},[_c('span',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"id":"","to":{ name: 'Landing', params: {} }}},[_c('span',{},[_vm._v("Home")])]),_vm._v(" | "),_c('span',{staticStyle:{"color":"#F5AD0B"}},[_vm._v("Alphabet")])],1),_c('h1',{staticStyle:{"font-weight":"bold"}},[_vm._v("Alphabet")])]),_c('div',{staticClass:"container-fluid bg",staticStyle:{"padding-top":"50px","padding-bottom":"50px"}},[_c('div',{staticClass:"container"},[_c('h4',{staticStyle:{"margin-bottom":"40px","font-weight":"600"}},[_vm._v("English alphabet")]),_c('p',[_vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo deleniti cumque odit, voluptatibus quisquam amet recusandae sequi nam in. Aspernatur voluptas vel corrupti illo deleniti consequatur reprehenderit vero quia odio.")]),_c('h4',{staticStyle:{"margin-bottom":"40px","margin-top":"40px","font-weight":"600"}},[_vm._v("Pronounciation of letters")]),_vm._l((15),function(comp,i){return _c('div',{key:i,staticClass:"row",staticStyle:{"background":"white","border-radius":"10px","padding":"10px","margin-bottom":"20px"}},[_vm._m(0,true),_vm._m(1,true),_vm._m(2,true),_vm._m(3,true),_vm._m(4,true),_vm._m(5,true)])})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 col-md-2 col-sm-12",staticStyle:{"font-weight":"600","border-right":"2px solid lightgrey","text-align":"center"}},[_vm._v(" Aa "),_c('img',{staticStyle:{"padding":"5px"},attrs:{"src":require("@/assets/categoryIcons/voice.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 col-md-2 col-sm-12"},[_vm._v(" Cat "),_c('img',{staticStyle:{"padding":"5px"},attrs:{"src":require("@/assets/categoryIcons/voice.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 col-md-2 col-sm-12"},[_vm._v(" Bat "),_c('img',{staticStyle:{"padding":"5px"},attrs:{"src":require("@/assets/categoryIcons/voice.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 col-md-2 col-sm-12"},[_vm._v(" Gas "),_c('img',{staticStyle:{"padding":"5px"},attrs:{"src":require("@/assets/categoryIcons/voice.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 col-md-2 col-sm-12"},[_vm._v(" Caesar "),_c('img',{staticStyle:{"padding":"5px"},attrs:{"src":require("@/assets/categoryIcons/voice.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 col-md-2 col-sm-12"},[_vm._v(" Jotaro "),_c('img',{staticStyle:{"padding":"5px"},attrs:{"src":require("@/assets/categoryIcons/voice.svg"),"alt":""}})])
}]

export { render, staticRenderFns }