<template>
  <div
      class="container-fluid"
      style="padding-top: 70px; padding-bottom: 70px; background: #F7F8FC;"
  >
    <div class="container">
      <div>
        <h1 style="font-weight: bold; margin-bottom: 50px;">
          {{ $t("CourseHeader") }}
        </h1>
        <p>{{ $t("CourseText") }}</p>
      </div>
      <div class="row">
        <div
            class="col-xl-3 col-md-3 col-sm-12 my-3"
            style="cursor: pointer"
            v-for="(cat, i) in catList"
            :key="i" v-show="i<show"
            @click.prevent="$emit('login')"
        >
          <div class="pad">
            <img :src="fileBase + '/photo/'+ cat.photo" style="width: 100px;" alt=""/>
            <h4 style="font-weight: 600;">{{ cat.name }}</h4>
            <p>{{ cat.courseCount }} {{ $t("categories") }}</p>
          </div>
        </div>
      </div>
    </div>
    <b-button
        v-if="show < catList.length"
        @click="show += 4"
        style="margin-bottom: 15px; margin-top: 25px; background: transparent; border: none; color: orange; text-decoration: underline;"
    >
      {{ $t("load") }}
    </b-button>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CategoryScroll",
  data() {
    return {
      catList: [],
      show: 4,
    };
  },

  computed: {
    ...mapState({
      apiBase: (state) => state.global.apiBase,
      fileBase: (state) => state.global.fileBase,
    })
  },
  async mounted() {
    this.getCategoryList();
  },

  methods: {
    getCategoryList() {
      fetch(this.apiBase + "/api/v1/categories", {
        method: "GET",
        redirect: "follow",
      })
          .then((response) => response.json())
          .then((result) => {
            this.catList = result.categories;
          })
          .catch((error) => console.log("error", error));
    },
  },

  components: {},
  props: {},

};
</script>

<style scoped>
.pad {
  background: white;
  padding: 30px;
  border-radius: 5px;
  height: 270px;
}

.pad:hover {
  margin-top: -5px !important;
  transition: 350ms;
  box-shadow: 0px 10px 20px 2px #bbb8b8;
}
</style>
