import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
// import VuePhoneNumberInput from 'vue-phone-number-input';
import router from './router'
import store from './store'
import i18n from './i18n'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueNotifications from 'vue-notifications';
import miniToastr from 'mini-toastr';


const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
}
miniToastr.init({types: toastTypes});

function toast ({title, message, type, timeout, cb}) {
  return miniToastr[type](message, title, timeout, cb)
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
}

Vue.config.productionTip = false

Vue.use(VueNotifications, options);
Vue.use(require('vue-moment'));
new Vue({
  router,
  store,
  i18n,
  VueTelInput,
  // VuePhoneNumberInput,
  render: h => h(App)
})
    .$mount('#app')
