import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../components/views/main/Landing.vue'
import Selection from '../components/views/starting/Selection.vue'
import Success from '../components/views/starting/Success.vue'
import Fail from '../components/views/starting/Fail.vue'
import Profile from '../components/views/profile/Profile.vue'
import Category from '../components/views/categories/Category.vue'
import CategoryList from '../components/views/categories/CategoryList.vue'
import Alphabet from '../components/views/categories/Alphabet.vue'
import PracticePage from '../components/views/practice/PracticePage.vue'
import CourseMaterial from '../components/views/practice/CourseMaterial.vue'
import Practice from '../components/views/practice/Practice.vue'
import PracticeN from '../components/views/practice/PracticeN.vue'
import Vocabulary from '../components/views/practice/Vocabulary.vue'

import InfoPage from "@/components/views/info/InfoPage.vue";

import store from "@/store";
import LpSuccess from "@/components/views/main/LpSuccess.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Landing',
        component: Landing
    },
    {
        path: '/page/:slug',
        name: 'InfoPage',
        component: InfoPage
    },
    {
        path: '/Selection',
        name: 'Selection',
        component: Selection
    },
    {
        path: '/Success',
        name: 'Success',
        component: Success
    },
    {
        path: '/lpSuccess',
        name: 'lpSuccess',
        component: LpSuccess
    },
    {
        path: '/Fail',
        name: 'Fail',
        component: Fail
    },
    {
        path: '/Profile',
        name: 'Profile',
        component: Profile,
    },
    {
        path: '/Category',
        name: 'Category',
        component: Category
    },
    {
        path: '/CategoryList/:id',
        name: 'CategoryList',
        component: CategoryList,
    },
    {
        path: '/Alphabet',
        name: 'Alphabet',
        component: Alphabet
    },

    {
        name: 'PracticePage',
        path: '/PracticePage',
        component: PracticePage,
        redirect: 'PracticePage/CourseMaterial',
        children: [
            {
                name: 'CourseMaterial',
                path: 'CourseMaterial/category:catId/progress:progressId/Course:courseId',
                component: CourseMaterial,
            },
            {
                name: 'Practice',
                path: 'Practice/category:catId/:progressId/Course:courseId',
                component: Practice,
            },
            {
                name: 'PracticeN',
                path: 'PracticeN/:progressId',
                component: PracticeN,
            },
            {
                name: 'Vocabulary',
                path: 'Vocabulary/:progressId',
                component: Vocabulary,
            },
        ],
    },
]


const router = new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes
})

router.beforeEach(async (to, from, next) => {

    let token = localStorage.getItem('token');

    if (token) {
        await store.dispatch('auth/setToken', token);
        next();
    } else {
        next();
    }
});

export default router
