<template>
  <div id="app">
    <Navbar @startLogin="startLoginProcess"/>
    <router-view :key="$route.path" @login="startLoginProcess" @profileUpdated="getProfileData"/>
    <Footer @login="startLoginProcess" />


    <b-modal size="md" ref="login-modal" centered id="login-modal" hide-footer hide-header body-class="loginModal">
      <div style="text-align: right; margin-bottom: -30px; margin-right: -30px;">
        <img src="@/assets/closeButt.svg" @click="hideMsisdnModal" style="cursor: pointer;" alt=""/>
      </div>
      <form @submit.prevent=" status()" class="d-block text-center">
        <h4 style="text-align: center; padding-top: 0px; font-weight: bold; color: #1E2E4D;"> Login now </h4>
        <p style="text-align: center; padding-top: 30px; padding-bottom: 30px; color: #5F6B81;"> Please enter your phone
          number to continue </p>
        <vue-tel-input style="border-radius: 5px;" class="" mode="international"
                       v-model="msisdn"></vue-tel-input>
        <b-button class="mainButton" type="submit"
                  style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-top: 30px; margin-bottom: 30px;">
          {{ $t("login") }}
        </b-button>
      </form>
    </b-modal>

    <b-modal size="md" ref="otp-modal" centered id="otp-modal" hide-footer hide-header body-class="loginModal">
      <div style="text-align: right; margin-bottom: -30px; margin-right: -30px;">
        <img src="@/assets/closeButt.svg" @click="hideOtpModal" style="cursor: pointer;" alt=""/>
      </div>
      <div class="d-block text-center">
        <form @submit.prevent="verify">
          <h4
              style="text-align: center; padding-top: 0px; font-weight: bold; color: #1E2E4D;"> {{
              $t("verCode")
            }} </h4>
          <p style="text-align: center; padding-top: 30px; color: #5F6B81;"> {{ $t("verRec") }} </p>
          <p style="color: #F4A900">"{{ msisdn }}"</p>
          <b-form-input id="input-1" v-model="otp" placeholder="Input otp here"
                        maxlength="5"></b-form-input>
          <span style="color: red;">{{ errorMsg }}</span> <br/>
          <b-button class="mainButton" type="submit"
                    style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-top: 30px; margin-bottom: 30px;">
            Submit
          </b-button>
        </form>
      </div>
    </b-modal>
    <b-modal size="md" ref="mess-modal" centered id="mess-modal" hide-footer hide-header body-class="loginModal">
      <div style="text-align: right; margin-bottom: -30px; margin-right: -30px;">
        <img src="@/assets/closeButt.svg" @click="hideMessageModal" style="cursor: pointer;" alt=""/>
      </div>
      <div class="d-block text-center"><h4
          style="text-align: center; margin-top: -40px; font-weight: bold; color: #1E2E4D;"> Sorry! </h4>
        <p style="text-align: center; padding-top: 30px; color: #5F6B81;"> {{ warnMessage }} </p>
      </div>
    </b-modal>


    <b-modal
        size="md"
        ref="activate-modal"
        centered
        id="activate-modal"
        hide-footer
        hide-header
        body-class="loginModal"
    >
      <div
          style="text-align: right; margin-bottom: -30px; margin-right: -30px;"
      >
        <img
            src="@/assets/closeButt.svg"
            @click="hideActivateModal"
            style="cursor: pointer;"
            alt=""
        />
      </div>
      <div class="d-block text-center" v-if="account">
        <h4
            style="text-align: center; padding-top: 0px; font-weight: bold; color: #1E2E4D;"
        >
          Activation
        </h4>
        <p style="text-align: center; padding-top: 30px; color: #5F6B81;">
          You want to activate service on:
        </p>
        <p style="color: #F4A900">"{{ account.number }}"</p>
        <b-button
            class="mainButton"
            @click="activate()"
            style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-top: 30px; margin-bottom: 30px;"
        >
          Activate
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import store from "@/store";
import {mapState} from "vuex";
import {VueTelInput} from "vue-tel-input";

export default {
  name: "App",
  data() {
    return {
      msisdn: "",
      otp: "",
      periodId: "",
      transactionId: "",
      errorMsg: "",
      warnMessage: "",
    };
  },

  computed: {
    ...mapState({
      token: state => state.auth.token,
      account: state => state.auth.account,
      applicationKey: state => state.global.applicationKey,
      apiBase: state => state.global.apiBase,
      ssoBase: state => state.global.ssoBase,
      settings: state => state.global.settings,
      authSettings: state => state.auth.settings,
    }),
    finalMsisdn() {
      return this.msisdn.substring(1).replace(/\s/g, "");
    }
  },

  watch: {
    token: function (newToken) {
      if (newToken) {
        this.getProfileData();
      } else {
        store.dispatch("auth/setAccount", null);
      }
    },
  },

  async mounted() {
    await store.dispatch("auth/setToken", localStorage.getItem("token"));
    this.loadLanguageList();
    this.appData();

    this.ssoSettings();

    if (this.token) {
      this.getProfileData();
    }
  },

  methods: {

    loadLanguageList() {
      fetch(this.apiBase + "/api/v1/languages", {method: "GET", redirect: "follow",})
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              this.$store.dispatch("global/setLanguages", result.languageList);
            }
          }).catch((error) => console.log("error", error));
    },

    startLoginProcess() {
      if(this.authSettings && this.authSettings.PHONE.subscription_flow==="lp") {
        this.lpStart();
      } else {
        this.showMsisdnModal();
      }
    },
    showMsisdnModal() {
      this.$bvModal.show("login-modal");
    },
    hideMsisdnModal() {
      this.$bvModal.hide("login-modal");
    }, showOtpModal() {
      this.$bvModal.show("otp-modal");
    }, hideOtpModal() {
      this.$bvModal.hide("otp-modal");
    },
    hideMessageModal() {
      this.$bvModal.hide("mess-modal");
    },
    hideActivateModal() {
      this.$bvModal.hide("activate-modal");
    },

    getProfileData() {

      const requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      fetch(this.ssoBase + "/profile/" + this.token, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.code === 200) {
              store.dispatch("auth/setAccount", result.account);
            } else {
              store.dispatch("auth/setAccount", null);
              store.dispatch("auth/setToken", null);
              localStorage.removeItem("token");
            }
          })
          .catch(error => console.log('error', error));

    },

    appData() {
      //todo move to state management
      fetch(
          this.apiBase + "/api/v1/settings",
          {method: "GET", redirect: "follow",}
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              this.$store.dispatch("global/setSettings", result.settings);

              let langCode = localStorage.getItem("language");
              if (langCode) {
                this.$i18n.locale = langCode;
              } else {
                this.$i18n.locale = result.settings.default_language_code;
                localStorage.setItem("language", result.settings.default_language_code);
              }


            } else {
              console.log("General settings", result);
            }
          })
          .catch((error) => console.log("error", error));
    },

    ssoSettings() {
      fetch(
          this.ssoBase + "/application/channels?appKeyword="+this.applicationKey,
          {method: "GET", redirect: "follow",}
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              this.$store.dispatch("auth/setSettings", result.channels);
            } else {
              console.log("General settings", result);
            }
          })
          .catch((error) => console.log("error", error));
    },

    status() {


      fetch(
          this.ssoBase + "/login/phone/status?appKeyword=" + this.applicationKey + "&msisdn=" + this.finalMsisdn,
          {method: "GET", redirect: "follow",}
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 404) {
              if (result.periods.length === 1) {
                this.periodId = result.periods[0].id;
                this.showOtpModal();
                this.reg();
              } else if (result.periods.length > 1) {
                //todo show period selection
                alert(result.periods);
              } else {
                alert("Error");
              }
            } else if (result.code === 200) {
              this.login();
            } else if (result.code === 412) {
              this.warnMessage = "Number format you entered, is not supported";
              this.$refs["mess-modal"].show();
            } else if (result.code === 402) {
              this.warnMessage = "Registration with phone, is disabled";
              this.$refs["mess-modal"].show();
            } else {
              console.log(result);
            }
          }).catch((error) => console.log("error", error));
    }, reg() {
      fetch(
          this.ssoBase + "/login/phone/otp?appKeyword=" + this.applicationKey + "&msisdn=" + this.finalMsisdn + "&periodId=" + this.periodId,
          {method: "POST", redirect: "follow",}
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              this.transactionId = result.transactionId;
            } else if (result.code === 402) {
              this.warnMessage = "Registration with phone, is disabled";
              this.$refs["mess-modal"].show();
            } else if (result.code === 404) {
              this.warnMessage = "Application is disabled";
              this.$refs["mess-modal"].show();
            } else if (result.code === 412) {
              this.warnMessage = "Number format you entered, is not supported";
              this.$refs["mess-modal"].show();
            } else if (result.code === 818) {
              this.warnMessage = "Subscription is disabled for now, try in a little while";
              this.$refs["mess-modal"].show();
            } else {
              console.log(result);
            }
          }).catch((error) => console.log("error", error));
    },
    login() {
      fetch(
          this.ssoBase + "/login/phone/otp?appKeyword=" + this.applicationKey + "&msisdn=" + this.finalMsisdn,
          {method: "POST", redirect: "follow",}
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              this.hideMsisdnModal();
              this.showOtpModal();
              this.transactionId = result.transactionId;
            } else if (result.code === 402) {
              this.warnMessage = "Registration with phone, is disabled";
              this.$refs["mess-modal"].show();
            } else if (result.code === 404) {
              this.warnMessage = "Application is disabled";
              this.$refs["mess-modal"].show();
            } else if (result.code === 412) {
              this.warnMessage = "Number format you entered, is not supported";
              this.$refs["mess-modal"].show();
            } else if (result.code === 818) {
              this.warnMessage = "Subscription is disabled for now, try in a little while";
              this.$refs["mess-modal"].show();
            } else {
              console.log(result);
            }
          }).catch((error) => console.log("error", error));
    },
    verify() {
      fetch(
          this.ssoBase + "/login/phone/verify?otp=" + this.otp + "&transactionId=" + this.transactionId,
          {method: "PUT", redirect: "follow",}
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              this.$store.dispatch("auth/setToken", result.token);
              localStorage.setItem("token", result.token);
              this.hideOtpModal();
            } else if (result.code === 404) {
              this.warnMessage = "Transaction was not found";
              this.$refs["mess-modal"].show();
            } else if (result.code === 407) {
              this.warnMessage = "Transaction has expired";
              this.$refs["mess-modal"].show();
            } else if (result.code === 406) {
              this.errorMsg = "Wrong Pin, re-enter OTP";
            } else {
              console.log(result);
            }
          }).catch((error) => console.log("error", error));
    },

    check() {

      fetch(
          this.apiBase + "/check/" + this.applicationKey + "/" + this.token,
          {method: "GET", redirect: "follow",}
      )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            if (result.code === 200) {
              console.log("Session start");
            } else if (result.code === 401) {
              console.log("Invalid Token");
            } else if (result.code === 404) {
              console.log("Application disabled");
            } else if (result.code === 411 || result.code === 409) {
              this.$bvModal.show("activate-modal");
            } else {
              console.log(result);
            }
          })
          .catch((error) => console.log("error", error));
    },

    activate() {
      fetch(
          this.apiBase + "/activate/" + this.applicationKey + "?token=" + this.token,
          {
            method: "POST",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              console.log("Successful activation");
              location.assign("/");
            } else if (result.code === 401) {
              console.log("Not authorised");
            } else if (result.code === 402) {
              console.log("Error");
            } else if (result.code === 404) {
              console.log("App not active");
            } else if (result.code === 409) {
              console.log("Not enough balance");
            } else {
              console.log(result);
            }
          })
          .catch((error) => console.log("error", error));
    },

    lpStart() {
      fetch(
          this.ssoBase + "/login/start?appKeyword=" + this.applicationKey,
          {method: "GET", redirect: "follow",}
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
               window.location.href = result.url;
            } else  {
              this.warnMessage = result.message;
              this.$refs["mess-modal"].show();
            }
          }).catch((error) => console.log("error", error));
    },
  },

  components: {
    Navbar,
    Footer, VueTelInput,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600;700&display=swap");

#app {
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

p {
  font-size: 18px;
}

.mainButton {
  background: #f4a900;
  border: none;
}

.mainButton:hover {
  background: #f5ad0b;
}

.mainButton:focus {
  background: #f4a900;
}

.defText {
  color: white;
}

.defText:hover {
  color: #f5ad0b;
}

.bg {
  background: #f7f8fc;
}

#arrow1 {
  padding-left: 5px;
  transition-duration: 300ms;
}

#arrow:hover #arrow1 {
  padding-left: 12px;
  transition-duration: 300ms;
}

.loginModal {
  background: #f7f8fc;
  padding-right: 40px;
  padding-left: 40px;
  border-radius: 10px;
}

.modal-md {
  max-width: 400px !important;
}  </style>