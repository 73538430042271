<template>
<div class="container-fluid mapBg" style="padding-top: 100px; padding-bottom: 100px;">
    <div class="container">
        <div>
            <h1 style="font-weight: bold; margin-bottom: 50px;">{{ $t('whyZuni') }}</h1>
            <p>{{ $t('whyZuniText') }}</p>
        </div>
        <div class="row">
            <div class="col-xl-4 col-md-4 col-sm-12" style="padding-top: 70px;">
                <h1 style="font-weight: 600; color: #F4A900;">30+</h1>
                <p>{{ $t('categories') }}</p>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-12" style="padding-top: 70px;">
                <h1 style="font-weight: 600; color: #F4A900;">1000+</h1>
                <p>{{ $t('questions') }}</p>
            </div>
             <div class="col-xl-4 col-md-4 col-sm-12" style="padding-top: 70px;">
                <h1 style="font-weight: 600; color: #F4A900;">150+</h1>
                <p>{{ $t('quests') }}</p>
            </div>
        </div>
    </div>
</div> 
</template>

<script>

export default {
  name: 'Why',
  data(){
      return{
        cookiesObject: '',
        }
  },
  async mounted(){
   
  },

  methods : {
     
  },

  components: {

  },
  props: {

  },
      
  computed: {

  },
}

</script>

<style scoped>
.mapBg{
background-image: linear-gradient(0deg, rgba(247, 248, 252, 0.5), rgba(247, 248, 252, 0.5)), url("assets/map.svg");
background-size: inherit;
}

.hClass{
    background: transparent;
}
.hClass:hover{
    background: white;
}

</style>
