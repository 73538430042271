export default {
    namespaced: true,
    actions: {
        setToken({commit}, token) {
            commit('setToken', token);
        },
        setAccount({commit}, account) {
            commit('setAccount', account);
        },
        setSettings({commit}, settings) {
            commit('setSettings', settings);
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setAccount(state, account) {
            state.account = account;
        },
        setSettings(state, settings) {
            state.settings = settings;
        }
    },
    state: {
        token: null,
        account: null,
        settings: {}
    },
    getters: {
        token: (state) => state.token,
        account: (state) => state.account,
        settings: (state) => state.settings
    },
}