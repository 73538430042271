import { render, staticRenderFns } from "./Selection.vue?vue&type=template&id=a2837ec0&scoped=true&"
import script from "./Selection.vue?vue&type=script&lang=js&"
export * from "./Selection.vue?vue&type=script&lang=js&"
import style0 from "./Selection.vue?vue&type=style&index=0&id=a2837ec0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2837ec0",
  null
  
)

export default component.exports