<template>
  <div class="" style="text-align: left; min-height: 75vh;" v-if="pageData">
    <div class="container" style="padding-top: 50px; padding-bottom: 50px;">
      <span>
        <router-link
            class=""
            style="text-decoration: none;"
            id=""
            :to="{ name: 'Landing', params: {} }"
        > <span class="">Home</span>
        </router-link>
        |
        <span style="color: #F5AD0B;">{{pageData.title}}</span>
      </span>
      <h1 class="font-weight-bold mt-3" >{{ pageData.title }}</h1>
    </div>
    <div  class="container" v-html="pageData.text"></div>
  </div>
  <div v-else>
    <div class="container d-flex justify-content-center align-items-center" style="text-align: left; min-height: 75vh;">
      Loading...
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "InfoPage",
  data() {
    return {
      pageData: null,
    };
  },
  async mounted() {
    this.getAbout();
  },

  computed: {
    ...mapState({
      apiBase: (state) => state.global.apiBase,
      languages: (state) => state.global.languages,

      slug(){
        return this.$route.params.slug;
      }
    }),
    languageId() {
      let language = this.languages.find((e) => e.code === this.$i18n.locale);
      if (language) {
        return language.id;
      } else {
        return 1;
      }
    },
  },

  methods: {
    getAbout() {

      fetch(
          this.apiBase + "/api/v1/page/"+this.slug+"?languageId=" + this.languageId,
          {
            method: "GET",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            if (result.code === 200) {
              this.pageData = result.page;
            } else {
              console.log(result);
            }
          })
          .catch((error) => console.log("error", error));
    },
  },

  components: {},

};
</script>

<style scoped></style>
