<template>
  <div
      class="container"
      style="padding-top: 10px; padding-bottom: 10px; min-height: 60vh;"
  >
    <div class="row">
      <div
          class="col-xl-4 col-md-4 col-sm-12 order-2 order-md-1"
          style="background: white; border-radius: 10px; padding: 30px; text-align: left; margin-bottom: 50px; height: 600px; overflow-y: scroll;"
      >
        <!-- <h4 style="margin-bottom: 30px; font-weight: 600; font-size: 16px;" >
        <img src="@/assets/flags/en.svg" style="" alt=""> English to 
        <img src="@/assets/flags/tu.svg" style="" alt=""> Russian 
      </h4> -->
        <p
            class="text" style="padding: 7px; border-radius: 7px;"
            v-for="(word, index) in words" :key="index"
            v-bind:class="{ text1: index === current }"
            @click="goToWord(index)"
        >
          <span>{{ word.original }}</span>
        </p>
      </div>
      <div class="col-xl-8 col-md-8 col-sm-12 p-3  order-1 order-md-2" v-if="current>-1">
        <div style="text-align: left;">
          <img
              v-if="words[current].photo"
              :src="fileBase+ '/photo/' + words[current].photo"
              style=""
              alt="" class="w-100 img-thumbnail"
          />
          <img
              v-else
              src="@/assets/noImage.png"
              class="w-100 img-thumbnail"
              alt=""
          />
          <div class="px-3">
            <p style="margin-top: 10px; color: grey;">Original</p>
            <h4 style="border-bottom: 1px solid lightgrey; padding-bottom: 10px;">
              {{ words[current].original }}
            </h4>
            <p style="margin-top: 10px; color: grey;">Translation</p>
            <h4 style="margin-bottom: 30px;">
              {{ words[current].translation }}
              <img
                  src="@/assets/categoryIcons/voice.svg" v-if="audioFemale"
                  @click="playFemaleAudio()"
                  style=""
                  alt=""
              />
              <img
                  src="@/assets/categoryIcons/voice.svg" v-if="audioMale"
                  @click="playMaleAudio()"
                  style=""
                  alt=""
              />
            </h4>
          </div>
          <!-- <b-button class="mainButton" @click="nextCourse()" style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-top: 20px;"> Finish course </b-button> -->
          <div class="text-center">
            <b-button
                style="border: none; font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-right: 10px;"
                :disabled="current === 0"
                @click="goToWord(current-1)"
            >{{ $t("previous") }}
            </b-button>
            <b-button
                class="mainButton"
                style="float: end; font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px;"
                v-if="current < words.length - 1"
                @click="goToWord(current+1)"
            >{{ $t("next") }}
            </b-button>
            <b-button
                v-else
                :to="{
              name: 'Practice',
              params: { id: progressId, courseId: courseId, catId: catId },
            }"
                style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #48BF92; border: none;"
            >{{ $t("gPractice") }}
            </b-button>

          </div>
        </div>
      </div>
    </div>
    <div class="" style=""></div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CourseMaterial",
  data() {
    return {
      progressId: "",
      courseId: 0,
      catId: 0,
      current: -1,
      maleVoice: "",
      femaleVoice: "",
      course: {},
      words: [],
    };
  },
  async mounted() {
    this.progressId = this.$route.params.progressId;
    this.courseId = this.$route.params.courseId;
    this.catId = this.$route.params.catId;

    this.getWords();
  },

  methods: {
    goToWord(index) {
      this.current = index;
      this.maleVoice = this.words[this.current].audioMale;
      this.femaleVoice = this.words[this.current].audioFemale;
    },
    getWords() {

      fetch(
          this.apiBase + "/api/v1/words?courseId=" + this.courseId + "&token=" + this.token + "&userProgressId=" + this.progressId,
          {
            method: "GET",
            redirect: "follow",
          }
      )
          .then((response) => response.json())
          .then((result) => {
            this.words = result.wordList;
            this.course = result.course;
            this.$emit('course', this.course);
            this.goToWord(0);
          })
          .catch((error) => console.log("error", error));
    },

    nextCourse() {

      fetch(
          this.apiBase + "/api/v1/learning/next?token=" + this.token + "&userProgressId=" + this.progressId,
          {
            method: "POST",
            redirect: "follow",
          }
      )
          .then((response) => response.text())
          .then((result) => {
            console.log(result);
            this.getWords();
          })
          .catch((error) => console.log("error", error));
    },

    playMaleAudio() {
      this.audioFemale.pause();
      this.audioFemale.currentTime = 0;
      console.log(this.audioMale);
      this.audioMale.play();
    },
    playFemaleAudio() {
      this.audioMale.pause();
      this.audioMale.currentTime = 0;
      this.audioFemale.play();
    }
  },

  components: {},
  props: {},

  computed: {

    ...mapState({
      apiBase: (state) => state.global.apiBase,
      fileBase: (state) => state.global.fileBase,
      token: state => state.auth.token
    }),
    audioMale() {
      if (this.maleVoice == null) {
        return null;
      }
      return new Audio(this.fileBase + "audio/" + this.maleVoice);
    },
    audioFemale() {
      if (this.femaleVoice == null) {
        return null;
      }
      return new Audio(this.fileBase + "audio/" + this.femaleVoice);
    },
  },
};
</script>

<style scoped>
.text1 {
  background: #e6e9ee;
  cursor: pointer;
}

.text:hover {
  background: #e6e9ee;
  cursor: pointer;
}
</style>
