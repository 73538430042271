<template>
  <div>
    <b-navbar class="sticky-top " toggleable="sm" style="background: #1C50AF; ">
      <b-navbar-brand to="/">
        <img src="@/assets/mainLogo.svg" alt=""/>
        <span style="font-weight: bold; color: white;"> Zunilingo</span>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">
        <img src="@/assets/navbarTggl.svg" style="width: 50px; height: 20px;" alt=""/>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav right>
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="!account" v-on:click="scrollToElement()">
            <span class="navItem"> {{ $t("categories") }} </span>
          </b-nav-item>
          <!-- <b-nav-item to="/Alphabet"> <span class="navItem"> {{ $t('alphabet') }} </span> </b-nav-item> -->
          <b-nav-item-dropdown no-caret right>
            <template #button-content>
              <span class="navItem">
              {{ $t("language") }}
              <LanguageIcon :code="$i18n.locale"/>
              </span>
            </template>
            <b-dropdown-item class="ddItem text-left" v-for="lang in languages" :key="lang.id"
                             @click="setLanguage(lang)">
              <LanguageIcon :code="lang.code"/>
              {{ lang.name }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-button v-if="!account" class="mainButton mr-4 ml-4" @click.prevent="$emit('startLogin')"
                    style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; ">
            {{ $t("login") }}
          </b-button>
          <b-nav-item-dropdown v-else text="left align" no-caret>
            <template #button-content>
              <b-button class="mainButton mr-4 ml-4"
                        style="font-weight: 600; padding: 5px 20px 5px 20px; border-radius: 5px; font-size: 15px; ">
                <img src="@/assets/profile.svg" style="width: 25px;" alt=""/>
                <span>{{ account.username }}</span>
              </b-button>
            </template>
            <!-- <b-dropdown-item class="" ><img src="@/assets/mobile.svg" style=""  alt=""> {{account.msisdn}} </b-dropdown-item> -->
            <b-dropdown-item class="" to="/Profile">
              <img src="@/assets/edit.svg" style="" alt=""/> {{ $t("settings") }}
            </b-dropdown-item>
            <b-dropdown-item class="" @click="logout()">
              <img src="@/assets/lgOut.svg" style="" alt=""/> {{ $t("logout") }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

  </div>
</template>

<script>  import {mapState} from "vuex";
import LanguageIcon from "@/components/LanguageIcon.vue";

export default {
  name: "Navbar",
  components: {LanguageIcon},
  data() {
    return {

    };
  },
  computed: {
    ...mapState({
      token: (state) => state.auth.token,
      account: (state) => state.auth.account,
      settings: (state) => state.auth.settings,
      languages: (state) => state.global.languages,
    }),
  },
  async mounted() {
  },
  methods: {

    scrollToElement() {
      if (window.innerWidth < 600) {
        window.scrollTo({top: 3600, left: 0, behavior: "smooth",});
      } else {
        window.scrollTo({top: 1750, left: 0, behavior: "smooth",});
      }
    },

    setLanguage(language) {
      this.$i18n.locale = language.code;
      localStorage.setItem("language", language.code);
    },

    logout() {
      localStorage.removeItem("token");
      this.$store.dispatch("setToken", null);
      window.location.reload();
    },
  },
  props: {},
};

</script>

<style scoped>
.navItem {
  color: #f7f8fc;
  font-size: 16px;
  font-weight: 600;
  padding-right: 10px;
  padding-left: 10px;
}

.navItem:hover {
  color: #f5ad0b;
}

.ddItem {
  text-align: right;
}

</style>